import { Body, Color, FaIcon, H3, Hbox } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { monthToString } from '@root/src/utils/date-parser/date-parser'
import * as React from 'react'
import {
  MagazineItemDownloadIcon,
  MagazineItemImgWrapper,
  MagazineItemStyled,
  MagazineItemTextWrapper
} from './magazine-item.component.style'

export interface Item {
  title: string
  url: string
  downloadUrl: string
  imageUrl: string
  descricao: string
  edicao: string
  mes: string
  ano: string
}

export interface MagazineItemProps {
  item: Item
  first?: boolean
  id: string
}

export const MagazineItem: React.FunctionComponent<MagazineItemProps> = ({ id, ...props }) => {
  const item = props.item
  return (
    <a id={`anchor-${id}`} href={item.downloadUrl} download target="_blank">
      <MagazineItemStyled key={item.title}>
        <MagazineItemImgWrapper first={props.first}>
          <MagazineItemDownloadIcon>
            <Hbox.Item hAlign="center">
              <FaIcon.Download size="3x" color={Color.White} />
            </Hbox.Item>
          </MagazineItemDownloadIcon>
          <LazyLoadImage
            src={item.imageUrl}
            aspectRatio={416 / 330}
            fitWidth
            alt={item.descricao}
          />
        </MagazineItemImgWrapper>

        <MagazineItemTextWrapper>
          <H3 cell>Edição {item.edicao}</H3>
          <Body>
            {monthToString(item.mes)} de {item.ano}
          </Body>
        </MagazineItemTextWrapper>
      </MagazineItemStyled>
    </a>
  )
}
