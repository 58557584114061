import { SEO } from '@app/components/legacy/mol.seo/seo.component'
import TitleWithBreadcrumbRow from '@app/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { PageProps } from '@app/utils/local-types'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import { getListJsonLd } from '@root/src/components/legacy/mol.seo/build-list-json-ld'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { CosmicjsInformacoesEstaticasMetadata } from '@root/src/data/graphql/graphql-types'
import {
  MagazineContentWrapper,
  MagazineItemWrapper
} from '@root/src/modules/magazine/component/magazine-content-wrapper.component'
import { Item, MagazineItem } from '@root/src/modules/magazine/component/magazine-item.component'
import { Col, Grid, Row } from 'atomic'
import { graphql } from 'gatsby'
import React from 'react'
import Title from 'site/src/components/landing-design-system/title'

import { Body } from './styled'
export interface MedicalMagazinesTemplateProps {
  list: Item[]
}

class MedicalMagazinesTemplate extends React.Component<PageProps<MedicalMagazinesTemplateProps>> {
  render() {
    const staticInfo = this.props.data.cosmicjsInformacoesEstaticas
      .metadata as CosmicjsInformacoesEstaticasMetadata
    return (
      <IndexLayout location={this.props.location}>
        {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' ? (
          <>
            {/* <span> AQUI É WEINMANN</span> */}
            <SEO
              jsonld={getListJsonLd(
                this.props.pageContext.list.map(item => ({ url: item.downloadUrl }))
              )}
              socialMedia={{
                canonicalUrl: `${this.props.data.site.siteMetadata.siteUrl}${this.props.location.pathname}${this.props.location.search}`,
                title: staticInfo.seo.titulo,
                image: staticInfo.seo.imagem.url,
                imageAlt: staticInfo.seo.imagemAlternativa,
                description: staticInfo.seo.descricao
              }}
            />

            <Grid>
              <TitleWithBreadcrumbRow />

              <Title color={'#373A3A'}>{staticInfo.titulo}</Title>
              <Body>{staticInfo.descricao}</Body>

              <Row>
                <Col xs={12}>
                  <MagazineContentWrapper className="magazineItemWeinmann">
                    {this.props.pageContext.list &&
                      this.props.pageContext.list.map((item, index) => (
                        <MagazineItemWrapper
                          id={`medical-magazine-wrapper-${index}`}
                          first={index === 0}
                          key={item.title}
                        >

                            <MagazineItem
                              id={`medical-magazine-${index}`}
                              item={item}
                              first={index === 0}
                            />

                        </MagazineItemWrapper>
                      ))}
                  </MagazineContentWrapper>
                </Col>
              </Row>
              <LargeSeparatorRow />
            </Grid>
          </>
        ) : (
          //a partir daqui é para todos os sites (fora Weinmann)
          <>
            <SEO
              jsonld={getListJsonLd(
                this.props.pageContext.list.map(item => ({ url: item.downloadUrl }))
              )}
              socialMedia={{
                canonicalUrl: `${this.props.data.site.siteMetadata.siteUrl}${this.props.location.pathname}${this.props.location.search}`,
                title: staticInfo.seo.titulo,
                image: staticInfo.seo.imagem.url,
                imageAlt: staticInfo.seo.imagemAlternativa,
                description: staticInfo.seo.descricao
              }}
            />

            <Grid>
              <TitleWithBreadcrumbRow title={staticInfo.titulo}>
                <Body>{staticInfo.descricao}</Body>
              </TitleWithBreadcrumbRow>
              <Row>
                <Col xs={12}>
                  <MagazineContentWrapper>
                    {this.props.pageContext.list &&
                      this.props.pageContext.list.map((item, index) => (
                        <MagazineItemWrapper
                          id={`medical-magazine-wrapper-${index}`}
                          first={index === 0}
                          key={item.title}
                        >
                          <MagazineItem
                            id={`medical-magazine-${index}`}
                            item={item}
                            first={index === 0}
                          />
                        </MagazineItemWrapper>
                      ))}
                  </MagazineContentWrapper>
                </Col>
              </Row>
              <LargeSeparatorRow />
            </Grid>
          </>
        )}
      </IndexLayout>
    )
  }
}

export default MedicalMagazinesTemplate

export const query = graphql`
  query RevistasMedicas {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cosmicjsInformacoesEstaticas(slug: { eq: "revistas-medicas" }) {
      metadata {
        titulo
        descricao
        seo {
          titulo
          descricao
          imagem {
            url
          }
          imagemAlternativa
        }
      }
    }
  }
`
