// tslint:disable

import { FaIcon } from 'atomic/legacy/atm.fa-icon'
import { Color } from 'atomic/legacy/obj.constants'
import styled from 'styled-components'

export const LazyLoadImageStyled = styled.img<{ loaded: boolean; height; round: boolean }>`
  display: block;
  width: 100%;
  transition: opacity 0.6s ease;
  opacity: ${props => (props.loaded ? '1' : '0')};
  height: ${props => (props.height ? props.height + 'px' : 'auto')};
  max-height: ${props => (props.loaded ? 'none' : '0')};
  border-radius: ${props => (props.round ? '50%' : '0')};
  -webkit-user-drag: none;
`

export const LazyLoadImgWrapper = styled.div<{
  height
  width
  fitWidth: boolean
  fitHeight: boolean
  loaded: boolean

}>`
  height: ${props => (props.height ? `${props.height}px` : '100%')};
  width: ${props => (props.width ? `${props.width}px` : 'auto')};

  > img {
    display: block;
    width: ${props => (props.fitWidth ? `100%` : '')};
    height: ${props => (props.fitHeight ? `100%` : '')};
    display: ${props => (props.loaded ? '' : 'none')};

    &.magazine-weinmann{
     border-radius: 4px;

    }
  }

}
`

interface PlaceholderStyledProps {
  aspectRatio: number
}

export const LazyLoadPlaceholderStyled = styled.div<PlaceholderStyledProps>`
  width: 100%;
  padding-bottom: ${props => (props.aspectRatio ? props.aspectRatio * 100 + '%' : '100%')};
  background-color: ${Color.GrayLight};
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
`

const placeholderImage = (height: number) => `
  color: ${Color.Gray};
  font-size: ${height}px;
  position: absolute;
  top: calc(50% - ${height / 2}px);
`

export const LazyLoadPlaceholderImageStyled = styled(FaIcon.Image)`
  ${placeholderImage(28)}
`

export const LazyLoadErrorImageStyled = styled(FaIcon.Ban)`
  ${placeholderImage(32)}
`
