// tslint:disable

import * as React from 'react'
import Imgix from 'react-imgix'
import Observer from 'react-intersection-observer'
import {
  LazyLoadErrorImageStyled,
  LazyLoadImgWrapper,
  LazyLoadPlaceholderImageStyled,
  LazyLoadPlaceholderStyled
} from './lazy-load-image.component.style'

interface LazyLoadImageProps {
  src: string
  alt: string
  /** height / width */
  aspectRatio?: number
  onClick?: () => void
  round?: boolean

  // https://github.com/imgix/react-imgix#basic-use-case
  width?: string | number
  height?: string | number
  sizes?: string
  fitWidth?: boolean
  fitHeight?: boolean
}

enum Status {
  Loading,
  Error,
  Loaded
}

interface LazyLoadImageState {
  status: Status
}

export class LazyLoadImage extends React.Component<LazyLoadImageProps, LazyLoadImageState> {
  constructor(props: LazyLoadImageProps) {
    super(props)
    this.state = {
      status: Status.Loading
    }
  }

  handleError = () => {
    this.setState({ status: Status.Error })
  }

  handleLoad = () => {
    this.setState({ status: Status.Loaded })
  }

  referImageWrapper = (imageWrapper: any) => {
    if (imageWrapper) {
      imageWrapper.onload = this.handleLoad
    }
  }

  shouldComponentUpdate(nextProps: LazyLoadImageProps, nextState: LazyLoadImageState) {
    return (
      nextState.status !== this.state.status ||
      nextProps.src !== this.props.src ||
      nextProps.alt !== this.props.alt ||
      nextProps.aspectRatio !== this.props.aspectRatio ||
      nextProps.round !== this.props.round ||
      nextProps.width !== this.props.width ||
      nextProps.height !== this.props.height ||
      nextProps.sizes !== this.props.sizes
    )
  }

  render() {
    if (!this.props.src) {
      console.error('Invalid src', this.props)
      this.setState({ status: Status.Error })
    }
    const aspectRatio =
      this.props.aspectRatio || (Number(this.props.height) || 0) / (Number(this.props.width) || 1)

    return (
      <Observer triggerOnce={true}>
        {({ inView, ref }) => {
          return (
            <>
              {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' ? (
                <LazyLoadImgWrapper
                  ref={ref as any}
                  height={this.props.height}
                  width={this.props.width}
                  fitWidth={this.props.fitWidth}
                  fitHeight={this.props.fitHeight}
                  loaded={this.state.status === Status.Loaded}
                  weinmann={this.props.weinmann}
                >
                  {this.state.status === Status.Loading && (
                    <LazyLoadPlaceholderStyled aspectRatio={aspectRatio}>
                      <LazyLoadPlaceholderImageStyled />
                    </LazyLoadPlaceholderStyled>
                  )}
                  {this.state.status === Status.Error && (
                    <LazyLoadPlaceholderStyled aspectRatio={aspectRatio}>
                      <LazyLoadErrorImageStyled />
                    </LazyLoadPlaceholderStyled>
                  )}
                  {((this.props.src && inView && this.state.status === Status.Loading) ||
                    this.state.status === Status.Loaded) && (
                    <Imgix
                      className="magazine-weinmann"
                      htmlAttributes={{ ref: this.referImageWrapper }}
                      alt={this.props.alt}
                      onClick={this.props.onClick}
                      src={this.props.src}
                      onLoad={this.handleLoad}
                      onError={this.handleError}
                      loaded={this.state.status === Status.Loaded}
                      round={this.props.round}
                      height={this.props.height}
                      width={this.props.width}
                      sizes={this.props.sizes}
                      weinmann={this.props.weinmann}
                      imgixParams={{ ar: `1:${aspectRatio}` }}
                    />
                  )}
                </LazyLoadImgWrapper>
               ):(
                <LazyLoadImgWrapper
                  ref={ref as any}
                  height={this.props.height}
                  width={this.props.width}
                  fitWidth={this.props.fitWidth}
                  fitHeight={this.props.fitHeight}
                  loaded={this.state.status === Status.Loaded}
                  weinmann={this.props.weinmann}
                >
                  {this.state.status === Status.Loading && (
                    <LazyLoadPlaceholderStyled aspectRatio={aspectRatio}>
                      <LazyLoadPlaceholderImageStyled />
                    </LazyLoadPlaceholderStyled>
                  )}
                  {this.state.status === Status.Error && (
                    <LazyLoadPlaceholderStyled aspectRatio={aspectRatio}>
                      <LazyLoadErrorImageStyled />
                    </LazyLoadPlaceholderStyled>
                  )}
                  {((this.props.src && inView && this.state.status === Status.Loading) ||
                    this.state.status === Status.Loaded) && (
                    <Imgix
                      htmlAttributes={{ ref: this.referImageWrapper }}
                      alt={this.props.alt}
                      onClick={this.props.onClick}
                      src={this.props.src}
                      onLoad={this.handleLoad}
                      onError={this.handleError}
                      loaded={this.state.status === Status.Loaded}
                      round={this.props.round}
                      height={this.props.height}
                      width={this.props.width}
                      sizes={this.props.sizes}
                      weinmann={this.props.weinmann}
                      imgixParams={{ ar: `1:${aspectRatio}` }}
                    />
                  )}
                </LazyLoadImgWrapper>
               )}
            </>
          )
        }}
      </Observer>
    )
  }
}