import styled from 'styled-components'
import { Spacing } from 'atomic'

export const MagazineContentWrapper = styled.ul`
  display: inline-grid;
  margin: 0;
  padding: 0;
  grid-column-gap: ${Spacing.Large};
  grid-row-gap: ${Spacing.Small};
  justify-items: stretch;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(154px, 1fr));
    grid-row-gap: ${Spacing.XLarge};


  }

  &.magazineItemWeinmann {
    h3 {
      color: #098a9c;
      font-size: 16px;
    }

    p {
      font-size: 14px;
      color:#302A38;
    }
  }
`
export const MagazineItemWrapper = styled.div<{ first: boolean }>`
  grid-column: ${props => (props.first ? '1/3' : 'auto')};
  grid-row: ${props => (props.first ? '1/3' : 'auto')};

  @media (max-width: 425px) {
    border-bottom: ${props => (props.first ? '1px solid #CCCCCC' : 'unset')};
    padding-bottom: ${props => (props.first ? '30px' : 'unset')};
    margin-bottom: ${props => (props.first ? '22px' : 'unset')};
  }

`
