import styled from 'styled-components'

import { Body as Description} from 'atomic'
import { Breadcrumb as BreadcrumbMagazine } from '@root/src/components/atm.breadcrumb/breadcrumb.component'


export const Body = styled(Description)`
  color: #302A38;
  margin-top: 25px;
  margin-bottom: 13px;

`

export const Breadcrumb = styled(BreadcrumbMagazine)`
  margin-top: 25px;
  margin-bottom: 13px;

`

