import { Color, Spacing } from 'atomic'
import styled from 'styled-components'

export const MagazineItemStyled = styled.li`
  position: relative;
  list-style-type: none;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 4px;
  flex-direction: column;

  .magazineItemWeinmann {
    h3 {
      color: #098a9c;
      font-size: 16px;
    }

    p {
      font-size: 14px color;
    }
  }
`
export const MagazineItemDownloadIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${Color.GrayXDark};
  opacity: 0;
  transition: all 0.2s ease-in-out;
  :hover {
    opacity: 0.7;
  }
`

export const MagazineItemImgWrapper = styled.div<{ first: boolean }>`
  flex: 1;
  position: relative;
  height: ${props => (props.first ? 473 : 207)};
  margin-bottom: ${Spacing.XSmall};
`

export const MagazineItemTextWrapper = styled.div`
  margin-top: auto;
`
